import React, {Component, Fragment} from 'react';
import { Map } from 'react-yandex-maps';
import Toggle from '@atlaskit/toggle';
import axios from 'axios';
import { Checkbox } from '@atlaskit/checkbox';
import Spinner from '@atlaskit/spinner';
import Boards from './Placemark';
import moment from 'moment';
import { filter, propEq, find } from 'ramda';
export default class YandexMap extends Component{

  state={
    authorName: null,
    loading: false,
    showCurrent: true,
    showPlanned: false,
    selectedBoard: null,
    currentData: [],
    plannedData: [],
    filter: {
      showType1: true,
      showType2: true,
      showType3: true,
      showType4: true,
      showType5: true
    }
  }

  showLoader(){
    this.setState({
      loading: true
    });
  }

  getAllBoardsData(){
    this.setState({
      loading: true
    });

    axios.get('https://api.ezhmap.musan.digital/all')
    .then(function (response) {
      const currentData = filter(propEq('is_planned', false), response.data);
      const plannedData = filter(propEq('is_planned', true), response.data);

      this.setState({
        currentData,
        plannedData,
        loading: false,
        selectedBoard: this.state.selectedBoard ? find(propEq('id', this.state.selectedBoard.id), response.data) : null
      });
    }.bind(this));

  }

  // async getCurrentData(){
  //   this.setState({
  //     loading: true
  //   });

  //   axios.get('https://kuoma.kz/current')
  //   .then(function (response) {
  //     this.setState({
  //       currentData: response.data,
  //       loading: false
  //     });
  //   }.bind(this));
  // }

  // async getPlannedData(){
  //   this.setState({
  //     loading: true
  //   });

  //   axios.get('https://kuoma.kz/planned')
  //   .then(function (response) {
  //     this.setState({
  //       plannedData: response.data,
  //       loading: false
  //     });
  //   }.bind(this));
  // }

  updateBoard(data){
    const authorName = this.state.authorName || "Неизв.";
    
    axios.post(`https://api.ezhmap.musan.digital/edit/${data.id}`, {
      coords: data.coords,
      edited_by: authorName,
    })
    .then(function (response) {
      this.getAllBoardsData();
    }.bind(this));
  }

  async switchFeatured(data){

    const is_featured = !data.is_featured;
    await axios.post(`https://api.ezhmap.musan.digital/feature/${data.id}`, {
      is_featured
    });

    this.getAllBoardsData();
  }

  updateAuthorName(name){
    this.setState({
      authorName: name
    })
  }

  filterChange(e){
    e.preventDefault();
    const type = e.target.value;
    const checked = e.target.checked;

    switch (parseInt(type)) {
      case 1:
        this.setState({
          filter: {
            ...this.state.filter,
            showType1: checked
          }
        });
        break;
      case 2:
        this.setState({
          filter: {
            ...this.state.filter,
            showType2: checked
          }
        });
        break;
      case 3:
        this.setState({
          filter: {
            ...this.state.filter,
            showType3: checked
          }
        });
        break;
      case 4:
        this.setState({
          filter: {
            ...this.state.filter,
            showType4: checked
          }
        });
        break;
      case 5:
        this.setState({
          filter: {
            ...this.state.filter,
            showType5: checked
          }
        });
        break;
      default:
        break;
    }
  }

  switchCurrentToggle(){
    this.setState({
      showCurrent: !this.state.showCurrent
    })
  }

  switchPlannedToggle(){
    this.setState({
      showPlanned: !this.state.showPlanned
    })
  }

  selectBoard(board){
    this.setState({
      selectedBoard: board
    })
  }

  componentDidMount(){
    this.getAllBoardsData();
  }

  render(){

    const {
      showCurrent,
      showPlanned,
      filter,
      selectedBoard,
      currentData,
      plannedData,
      loading,
      authorName
    } = this.state;

    return <Fragment>
      <Map className="map"
        defaultState={{
          center: [51.151859, 71.423977],
          zoom: 12,
          controls: ['zoomControl']
        }}
        modules={['control.ZoomControl']}
      >
        {showCurrent &&
          <Boards
            type="current"
            filter={filter}
            selectBoard={this.selectBoard.bind(this)}
            currentData={currentData}
            updateBoard={this.updateBoard.bind(this)}
            updateAuthorName={this.updateAuthorName.bind(this)}
            authorName={authorName}
            showLoader={this.showLoader.bind(this)}
          />}
        {showPlanned && <Boards
            type="planned"
            selectBoard={this.selectBoard.bind(this)}
            filter={filter}
            plannedData={plannedData}
            updateBoard={this.updateBoard.bind(this)}
            updateAuthorName={this.updateAuthorName.bind(this)}
            authorName={authorName}
            showLoader={this.showLoader.bind(this)}
            />}
      </Map>
      <header className="header">
        <div className="row center-xs around-xs">
          <img src="/img/logo.svg" height="40" alt="Елорда Жарнама" />
          <div className="switch-col">
            <i>Текущие</i>
            <Toggle size="large" onChange={this.switchCurrentToggle.bind(this)} isDefaultChecked={showCurrent} />
          </div>
          <div className="switch-col">
            <i>План.</i>
            <Toggle size="large" onChange={this.switchPlannedToggle.bind(this)} isDefaultChecked={showPlanned} />
          </div>
        </div>
        <div className="row center-xs around-xs" style={{
          padding: '10px 0',
          fontSize: '13px',
          lineHeight: '20px',
        }}>
          <Checkbox
            onChange={this.filterChange.bind(this)}
            defaultChecked={filter.showType1}
            value="1"
            label="Билборд"
            name="checkbox-basic"
          />
          <Checkbox
            onChange={this.filterChange.bind(this)}
            defaultChecked={filter.showType2}
            value="2"
            label="Скроллер"
            name="checkbox-basic"
          />
        </div>
        <div className="row center-xs around-xs" style={{
          fontSize: '13px',
          lineHeight: '20px',
        }}>
          <Checkbox
            onChange={this.filterChange.bind(this)}
            defaultChecked={filter.showType4}
            value="4"
            label="Cити-Тривижн"
            name="checkbox-basic"
          />
          <Checkbox
            onChange={this.filterChange.bind(this)}
            defaultChecked={filter.showType5}
            value="5"
            label="Ротонда"
            name="checkbox-basic"
          />
        </div>
        {loading && <div className="loading-overlay"><Spinner size="large" /></div>}
        <div className="row">
          {selectedBoard && (<div style={{
            width: '100%',
            paddingTop: '10px'
          }}><h6>Адрес</h6>
          <h2>{selectedBoard.address}</h2>
          <h6>Тип конструкции / Наименование</h6>
          <h2>{selectedBoard.name}</h2>
          {selectedBoard.is_planned && <Fragment>
            <h6>Приоритетность</h6>
            <Checkbox
              key={`priority-${selectedBoard.id}`}
              onChange={()=>this.switchFeatured(selectedBoard)}
              defaultChecked={selectedBoard.is_featured}
              value="priority"
              label="Приоритетный"
              name="checkbox-basic"
            />
          </Fragment>}
          {selectedBoard.edited_by && <Fragment>
            <h6>Посл. изменения гео-позиции</h6>
            <h2>{moment(selectedBoard.updatedAt).format('HH:mm:ss DD/MM/YYYY')} — {selectedBoard.edited_by || "Неизвест."}</h2>
            <h6>Изначальная гео-позиция</h6>
            <h2><a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/?q=${selectedBoard.initial_coords}`}>Открыть в Google Maps 🔗</a></h2>
          </Fragment>}
          </div>)}
        </div>
      </header>
    </Fragment>
  }

}

