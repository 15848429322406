import React from 'react';
import { YMaps } from 'react-yandex-maps';
import YandexMap from './Components/Map';

function App() {
  return (
    <YMaps>
      <YandexMap />
    </YMaps>
  );
}

export default App;
