import React, {Fragment, Component} from 'react';
import { Placemark } from 'react-yandex-maps';
import { join } from 'ramda';

export default class Boards extends Component{

  getCurrent(){
    const currentBoards = this.props.currentData;
    const filter = this.props.filter;

    const isVisible = (type) => {
      switch (parseInt(type)) {
        case 1:
          return filter.showType1;
        case 2:
          return filter.showType2;
        case 3:
          return filter.showType3;
        case 4:
          return filter.showType4;
        case 5:
          return filter.showType5;
        default:
          return false;
      }
    }

    const getIcon = (type) => {
      switch (parseInt(type)) {
        case 1:
          return '/img/board1.png';
        case 2:
          return '/img/board2.png';
        case 3:
          return '/img/board3.png';
        case 4:
          return '/img/board3.png';
        case 5:
          return '/img/board4.png';
        default:
          return false;
      }
    }

    return <Fragment>{
      currentBoards.map((e, i) => {
        return <Placemark
          key={`place-${e.id}-${i}`}
          geometry={e.coords.split(', ')}
          options={{
            // preset: 'islands#redDotIcon',
            draggable: true,
            visible: isVisible(e.type),
            iconLayout: 'default#image',
            iconImageHref: getIcon(e.type),
            iconImageSize: [39, 42],
          }}
          onDragEnd ={async event => {

            const coords = join(', ', event.originalEvent.target.geometry.getCoordinates());

            if(!this.props.authorName){
              const authorName = prompt("Пожалуйста, введите Ваше имя");

              if (!authorName) {
                alert("Вам нужно ввести имя");
                return;
              }

              this.props.updateAuthorName(authorName);
            }

            this.props.updateBoard({
              coords,
              id: e.id
            });
          }}
          onClick = {
            event => {
              event.preventDefault();
              this.props.selectBoard(e);
            }
          }
        />
        
      })
    }
    </Fragment>
  }

  getPlanned(){
    const plannedBoards = this.props.plannedData;

    return <Fragment>{
      plannedBoards.map((e, i) => {
        return <Placemark
          key={`place-planned-${e.id}-${i}`}
          geometry={e.coords.split(', ')}
          options={{
            preset: e.is_featured ? 'islands#orangeDotIcon' : 'islands#blueDotIcon',
            draggable: true,
          }}
          onDragEnd ={async event => {
            const coords = join(', ', event.originalEvent.target.geometry.getCoordinates());

            if(!this.props.authorName){
              const authorName = prompt("Пожалуйста, введите Ваше имя");
              if (!authorName) {
                alert("Вам нужно ввести имя");
                return;
              }
              this.props.updateAuthorName(authorName);
            }
           
            this.props.updateBoard({
              coords,
              id: e.id
            });
          }}
          onClick = {
            event => {
              event.preventDefault();
              this.props.selectBoard(e);
            }
          }
        />
        
      })
    }
    </Fragment>
  }

  render(){
    const {type} = this.props;
    return <Fragment>
      {type === "current" ? this.getCurrent() : this.getPlanned()}
    </Fragment>
  }
}

